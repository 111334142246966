import React from 'react';
import './printableActivities.scss';  // Estilos específicos para Actividades Imprimibles

//impport elementos de react
import { Row, Col, Pagination } from 'react-bootstrap';

//import componentes y vistas
import SubComponent from '../subComponent/subComponent';

//import Imagenes
import printableAzul from '../../img/imgResources/printableAzul.png';
import printableAmar from '../../img/imgResources/printableAmar.png';
import printableRoj from '../../img/imgResources/printableRoj.png';
import printableNaran from '../../img/imgResources/printableNar.png';
import printableVer from '../../img/imgResources/printableVer.png';

export interface Props { Arrayresources: any, grade: any, subject: any }

export default class PrintableActivities extends React.Component<Props, {
    imagResourcesPrintable: any,
    resources: any,
    currentPage: any,
    elementPage: any
}> {

    constructor(props: any) {
        super(props);
        this.state = {
            imagResourcesPrintable: [printableAzul, printableAmar, printableRoj, printableNaran, printableVer],
            resources: this.props.Arrayresources,
            currentPage: 1,
            elementPage: 6,
        };
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() { }

    handleClick(event: any) {
        event.preventDefault();
        this.setState({ currentPage: Number(event.target.id) });
    }

    render() {
        const { resources, currentPage, elementPage, imagResourcesPrintable } = this.state;

        //-----------------------------Paginación-----------------------------------
        const indexOfLastTodo = currentPage * elementPage;
        const indexOfFirstTodo = indexOfLastTodo - elementPage;
        const currentResources = resources.slice(indexOfFirstTodo, indexOfLastTodo);

        let active = currentPage;
        let numberPages = Math.ceil(resources.length / elementPage);
        let items = [];
        for (let number = 1; number <= numberPages; number++) {
            items.push(
                <Pagination.Item key={number} id={number} active={number === active} onClick={this.handleClick}>
                    {number}
                </Pagination.Item>,
            );
        }

        const paginationBasic = (
            <div><Pagination>{items}</Pagination><br /></div>
        );
        //---------------------------------------------------------------------------------
        return (
            <div>
                <Row className="w-100 m-0 h-100 pt-5">
                    <Col className="justify-content-center align-self-center text-center p-3" md={4}>
                        <div className="af-lineOrange"></div>
                    </Col>
                    <Col className="m-0 p-0" md={4}>
                        <h2 className="af-tittleLesson">Actividades Imprimibles</h2>
                    </Col>
                    <Col className="justify-content-center align-self-center text-center p-3" md={4}>
                        <div className="af-lineOrange"></div>
                    </Col>
                </Row>

                <Row>
                    <p className="af-textComponent p-4">Estas actividades pueden imprimirse, Son ideales para promover el aprendizaje activo fuera del entorno digital.</p>

                    {currentResources.map((resour: any, i: any) => {
                        let indexImg = i % 5;
                        return (
                            <Col key={i} md={4}>
                                {/* Se realiza el ajuste para que se abra el recurso directamente al hacer clic */}
                                <a href={resour.resource_url} target="_blank" rel="noopener noreferrer">
                                    <SubComponent
                                        tittle={`PA${(i + 1) + indexOfFirstTodo}.`}
                                        url={resour.resource_url}
                                        src={imagResourcesPrintable[indexImg]}
                                        desc={resour.dba_name}
                                    ></SubComponent>
                                </a>
                            </Col>
                        )
                    })}

                    {resources.length > elementPage &&
                        <Row className="w-100 justify-content-center align-self-center text-center">
                            {paginationBasic}
                        </Row>
                    }
                </Row>
            </div>
        )
    }
}
