import React from "react";
import "../reportTeacher.scss";

//impport elementos de react
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Title from "../../AdminUsers/Title/Title";
import DBA from "../../DBAReportTeach/DBAReportTeach";
import { GET_AXIOS } from "./../../funtionsREST";

//import imagenes
import IconInteractive from "../../../img/icon-interactive.svg";
import IconVideo from "../../../img/icon-video.svg";
import IconMen from "../../../img/icon-Men.svg";
import IconVirtual from "../../../img/icon-virtual.svg";
import IconButtonSend from "../../../img/icon-button-send.svg";
import IconMateEdu from "../../../img/icon-mateEdu.svg";

//import components and views
import LoadingPage from "../../spinner/loadingPage";


export interface Props {
  graphics: Array<string>;
  capitalize: any;
}

interface IState {
  grado: string;
  grupo: string;
  area: string;
  sede: string;
  institucion: string;
  grado2: string;
  // grupo2: string;
  area2: string;
  sede2: string;
  institucion2: string;
  showReportButton: boolean;
  showDiseableReportButton: boolean;
  showReport: boolean;
  showReportError: boolean;
  showNotGroup: boolean;
  insti: Array<object>;
  hqs: Array<object>;
  grades: Array<object>;
  groups: Array<object>;
  knowledgeAreas: Array<object>;
  reports: Array<report>;
  disableGrade: boolean;
  disableArea: boolean;
}

interface report {
  id: number;
  resource_type_name: string;
  totals: Array<object>;
}

export default class ReportRect extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    // No llames this.setState() aquí!
    this.state = {
      grado: "-1",
      grupo: "-1",
      area: "-1",
      sede: "-1",
      institucion: "-1",
      grado2: "-1",
      area2: "-1",
      sede2: "-1",
      institucion2: "-1",
      showReport: false,
      showReportError: false,
      showDiseableReportButton: false,
      showReportButton: false,
      showNotGroup: false,
      disableArea: false,
      disableGrade: false,
      insti: [],
      hqs: [],
      grades: [],
      groups: [],
      knowledgeAreas: [],
      reports: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.GenerateReport = this.GenerateReport.bind(this);
    //this.handleSelectInstitution = this.handleSelectInstitution.bind(this);
    this.handleSelectGrade = this.handleSelectGrade.bind(this);
  }

  getValueFromArray(arr: Array<object>, idToSearch: string, toReturn: any) {
    const _ele: any = arr.find((el: any) => el.id == idToSearch);
    if (_ele) {
      return _ele[toReturn];
    }
  }

  handleInputChange(event: React.FormEvent<HTMLInputElement>) {
    const target = event.currentTarget;
    const value = target.value;
    const name = target.name;
    const me = this;

    this.setState({
      showReport: false,
      showReportButton: false,
      showDiseableReportButton: false,
      showReportError: false,
    });

    me.setState(
      (prevState) => ({
        ...prevState,
        [name]: value,
        showReportButton: true,
      }),
      () => {
        if (
          me.state.grado !== "-1" &&
          me.state.institucion !== "-1" &&
          me.state.area !== "-1"
        )
          me.setState({ showDiseableReportButton: true });
      }
    );
  }

  GenerateReport(event: React.FormEvent<HTMLFormElement>) {
    console.log("hola");
    const jsonToReport = {
      institution: this.state.institucion,
      grade: this.state.grado,
      knowledge_area: this.state.area,
    };

    event.preventDefault();

    if (
      this.state.grado !== "-1" &&
      this.state.institucion !== "-1" &&
      this.state.area !== "-1"
    ) {
      GET_AXIOS(
        `stats/byAreaGradeTeachers/${jsonToReport.institution}/${jsonToReport.grade}/${jsonToReport.knowledge_area}`
      )
        .then((res) => {
          console.log(res.data);
          if (res.data !== undefined && res.data !== false) {
            this.setState({ reports: res.data }, () => {
              this.setState({
                grado2: jsonToReport.grade,
                area2: jsonToReport.knowledge_area,
                institucion2: jsonToReport.institution,
                showReport: true,
                showReportError: false,
              });
            });
          } else {
            this.setState({
              showReport: false,
              showReportError: true,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            showReport: false,
            showReportError: true,
          });
        });
    }
  }

  generatePDF() {
    const { grado, area, institucion } = this.state;
    window.open(
      `${process.env.REACT_APP_API_ENDPOINT}stats/generateReport?institution=${institucion}&grade=${grado}&knowledge_area=${area}`,
      "_blank"
    );
  }

  componentDidMount() {
    GET_AXIOS("users/fisrtLocChildren")
      .then((res) => {
        console.log(res.data);
        const institution_id = res.data[0].institution_id
        this.setState({ institucion: institution_id })
        if (institution_id !== "-1") {
          console.log(institution_id);
          this.getGradesDB(institution_id);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getGradesDB(value: any) {
    GET_AXIOS(`grades/byInst/${value}`)
      .then((res) => {
        console.log(res);
        const _data: Array<object> = this.props.capitalize(
          res.data,
          "grade_name"
        );
        this.setState({ grades: _data, disableGrade: true });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handleSelectGrade(event: React.FormEvent<HTMLInputElement>) {
    const target = event.currentTarget;
    const value = target.value;

    this.setState({
      showReport: false,
      showReportButton: false,
      showDiseableReportButton: false,
      showReportError: false,
      grado: value,
    });

    if (value === "-1") {
      this.setState({ disableArea: false });
      this.setState((prevState) => ({
        ...prevState,
        area: "-1",
        knowledgeAreas: [],
      }));
    }

    if (value !== "-1") {
      this.getAreas(value);
    }

  }

  getAreas(grades: any) {
    GET_AXIOS(`users/knowledgeAreas/${grades}`)
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          area: "-1",
          knowledgeAreas: [],
        }));
        const _data: Array<object> = this.props.capitalize(
          res.data,
          "knowledge_area_name"
        );
        this.setState({ knowledgeAreas: _data, disableArea: true });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    let { grades, knowledgeAreas, disableArea, disableGrade } = this.state;

    return (
      <>
        <Row className="justify-content-center align-self-center text-center">
          <Row className="w-100  h-100 pt-5">
            <Col>
              <h2 className="title-report">ESTIMADO(A) RECTOR(A),</h2>
            </Col>
          </Row>
          <Row className="justify-content-center align-self-center text-center w-100  h-100">
            <Col
              className="justify-content-center align-self-center text-center p-0"
              md={12}
            >
              <p>
                En el siguiente informe encontrará el estado de avance en
                relación con el acceso y uso de los contenidos y recursos por parte de los docentes de su institución educativa, según
                el ciclo y el área consultada.
              </p>
            </Col>
          </Row>
          <Row className="w-100 mb-4">
            <Col className="d-flex justify-content-center">
              <div className="separator" />
            </Col>
          </Row>
          <Form className="container-fluid form-report-Infor" onSubmit={this.GenerateReport}>
            <Row className="justify-content-center align-self-center text-center">
              <Col
                className="justify-content-center align-self-center text-center"
                md={6}
              >
                <Form.Control
                  className="af-reportselect w-100 m-1"
                  name="grado"
                  value={this.state.grado}
                  defaultValue={"-1"}
                  onChange={this.handleSelectGrade}
                  as="select"
                  required
                  disabled={disableGrade === false}
                >
                  <option value="-1">Escoge el grado</option>
                  {grades.map((grade: any, i: number) => (
                    <option value={grade.id} key={i}>
                      {grade.grade_name.toUpperCase()}
                    </option>
                  ))}
                </Form.Control>
              </Col>

              <Col
                className="justify-content-center align-self-center text-center"
                md={6}
              >
                <Form.Control
                  className="af-reportselect w-100 m-1"
                  name="area"
                  value={this.state.area}
                  defaultValue={"-1"}
                  onChange={this.handleInputChange}
                  as="select"
                  required
                  disabled={disableArea === false}
                >
                  <option value="-1">Escoge el área</option>
                  {knowledgeAreas.map((ka: any, i: number) => (
                    <option value={ka.id} key={i}>
                      {ka.knowledge_area_name.toUpperCase()}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="d-flex align-items-center justify-content-center generate-report">
                {this.state.showReportButton &&
                  (this.state.showDiseableReportButton === true ? (
                    <Button
                      variant="primary"
                      type="submit"
                      className="generate-report-button d-flex justify-content-center align-items-center"
                      block
                    >
                      Generar Reporte
                      <img
                        src={IconButtonSend}
                        alt=""
                        className="ml-3 icon-generate-report"
                      />
                    </Button>
                  ) : (
                      <Button
                        variant="primary"
                        type="submit"
                        className="generate-report-button d-flex justify-content-center align-items-center"
                        block
                        disabled
                      >
                        Generar Reporte
                        <img
                          src={IconButtonSend}
                          alt=""
                          className="ml-3 icon-generate-report"
                        />
                      </Button>
                    ))}
              </Col>
            </Row>
          </Form>
          {this.state.showNotGroup && (
            <Container>
              <Row>
                <Col className="" md={12}>
                  <h4>No hay grupos disponibles en este momento.</h4>
                </Col>
              </Row>
            </Container>
          )}
          {this.state.showReport && (
            <LoadingPage>
              <Container fluid>
                <Row>
                  <Col>
                    <Title>Informe</Title>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>
                      En el siguiente informe usted encontrará el estado de avance
                      en relación con el acceso y uso por parte de los docentes de los contenidos y/o
                      recursos, según el grado y el aréa
                      consultados.
                  </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <p className="text-center selected-type w-100 p-2">
                      Grado:{" "}
                      {this.getValueFromArray(
                        this.state.grades,
                        this.state.grado2,
                        "grade_name"
                      )}
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="text-center selected-type w-100 p-2">
                      Área:{" "}
                      {this.getValueFromArray(
                        this.state.knowledgeAreas,
                        this.state.area2,
                        "knowledge_area_name"
                      )}
                    </p>
                  </Col>
                </Row>
                {this.state.reports.filter((data: any) => data.id === 1)[0] &&
                  <Row className="mt-4 mb-2">
                    <Col className="d-flex subtitle-report">
                      <img src={IconVideo} alt="" className="icon-report" />
                      <h3>Videos:</h3>
                    </Col>
                  </Row>
                }
                {this.state.reports.filter((data: any) => data.id === 1)[0]
                  ? this.state.reports
                    .filter((data: any) => data.id === 1)[0]
                    .totals.map((value: any) => {
                      return (
                        <DBA
                          atribute="videos"
                          dba={value.dba}
                          total={value.total}
                          student={value.users}
                          type={1}
                        ></DBA>
                      );
                    })
                  : ""}
                {this.state.reports.filter((data: any) => data.id === 2)[0] &&
                  <Row className="mt-4 mb-2">
                    <Col className="d-flex subtitle-report">
                      <img src={IconInteractive} alt="" className="icon-report" />
                      <h3>Actividades interactivas:</h3>
                    </Col>
                  </Row>
                }
                {this.state.reports.filter((data: any) => data.id === 2)[0]
                  ? this.state.reports
                    .filter((data: any) => data.id === 2)[0]
                    .totals.map((value: any) => {
                      return (
                        <DBA
                          atribute="actividades"
                          dba={value.dba}
                          total={value.total}
                          student={value.users}
                          type={1}
                        ></DBA>
                      );
                    })
                  : ""}
                {this.state.reports.filter((data: any) => data.id === 3)[0] &&
                  <Row className="mt-4 mb-2">
                    <Col className="d-flex subtitle-report">
                      <img src={IconMen} alt="" className="icon-report" />
                      <h3>Recursos MEN:</h3>
                    </Col>
                  </Row>
                }
                {this.state.reports.filter((data: any) => data.id === 3)[0]
                  ? this.state.reports
                    .filter((data: any) => data.id === 3)[0]
                    .totals.map((value: any) => {
                      return (
                        <DBA
                          atribute="recursos"
                          dba={value.dba}
                          total={value.total}
                          student={value.users}
                          type={1}
                        ></DBA>
                      );
                    })
                  : ""}
                {this.state.reports.filter((data: any) => data.id === 4)[0] &&
                  <Row className="mt-4 mb-2">
                    <Col className="d-flex subtitle-report">
                      <img src={IconVirtual} alt="" className="icon-report" />
                      <h3>Aula virtual:</h3>
                    </Col>
                  </Row>
                }
                {this.state.reports.filter((data: any) => data.id === 4)[0]
                  ? this.state.reports
                    .filter((data: any) => data.id === 4)[0]
                    .totals.map((value: any) => {
                      return (
                        <DBA
                          atribute="contenidos"
                          dba={value.dba}
                          total={value.total}
                          student={value.users}
                          type={1}
                        ></DBA>
                      );
                    })
                  : ""}
				  {this.state.reports.filter((data: any) => data.id === 6)[0] &&
					<Row className="mt-4 mb-2">
					  <Col className="d-flex subtitle-report">
						<img src={IconVirtual} alt="" className="icon-report" />
						<h3>Modelos Test:</h3>
					  </Col>
					</Row>
				  }
				  {this.state.reports.filter((data: any) => data.id === 6)[0]
					? this.state.reports
					  .filter((data: any) => data.id === 6)[0]
					  .totals.map((value: any) => {
						return (
						  <DBA
							atribute="contenidos"
							dba={value.dba}
							total={value.total}
							student={value.users}
							type={1}
						  ></DBA>
						);
					  })
					: ""}

                {this.state.reports.filter((data: any) => data.id === 5)[0] &&
                  <Row className="mt-4 mb-2">
                    <Col className="d-flex subtitle-report">
                      <img src={IconMateEdu} alt="" className="icon-report" />
                      <h3>Materiales Educativos:</h3>
                    </Col>
                  </Row>
                }
                {this.state.reports.filter((data: any) => data.id === 5)[0]
                  ? this.state.reports
                    .filter((data: any) => data.id === 5)[0]
                    .totals.map((value: any) => {
                      return (
                        <DBA
                          atribute="contenidos"
                          dba={value.dba}
                          total={value.total}
                          student={value.users}
                          type={1}
                        ></DBA>
                      );
                    })
                  : ""}

                <Row className="my-4">
                  <Col md={12}>
                    <Button
                      variant="primary"
                      type="button"
                      className="Butoon-generate-report"
                      block
                      onClick={() => this.generatePDF()}
                    >
                      Para generar el reporte detallado por docente haz clic
                      aquí.
                  </Button>
                  </Col>
                </Row>
              </Container>
            </LoadingPage>
          )}
          {this.state.showReportError && (
            <Container className="mt-4" fluid>
              <Row>
                <Col className="" md={12}>
                  <h4>No hay reporte disponibles en este momento.</h4>
                </Col>
              </Row>
            </Container>
          )}
        </Row>
      </>
    );
  }
}
